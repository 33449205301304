import React from "react";
import './CSS/PublishPage.css';

import { API_URL } from './settings.json'

class PublishPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "",
      username: "",
      content: "",
      tags: "",
      sendingToServer: false
    }
    this.fileInput = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({sendingToServer: true});
    this.encodeImageFileAsURL(this.fileInput.current).then(file_content => {
      const tags = this.state.tags.trim() ? this.state.tags.split(",").map(tag => tag.trim()).filter(tag => tag.length>2).slice(0, 5) : null
      const data = {
        title: this.state.title.trim(),
        username: this.state.username.trim(),
        content: this.state.content.trim(),
        image: file_content,
        tags: tags
      }
      console.debug(data);

      fetch(API_URL+"/posts", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      }).then(resp => {
        if (resp.status === 200) {
          // post went well
          return resp.json()
        } else if (resp.status === 409) {
          // duplicated post
          alert("You have already published a similar post, please avoid spam!");
        } else {
          // unknown error
          console.error("Something went wrong", resp);
          alert(`An unknown error has occurred: [${resp.status}] ${resp.statusText}`);
        }
      }).then(dataJson => {
        // post went well -> send to post page
        if (dataJson) {
          this.props.history.push('/post/'+dataJson.id)
        }
        // treated error -> do nothing
        this.setState({sendingToServer: false});
      }).catch(err => {
        // probably an error while parsing JSON
        console.error(err);
        this.setState({sendingToServer: false});
      })
    });
  }

  encodeImageFileAsURL(element) {
    var file = element.files[0];

    const temporaryFileReader = new FileReader();
    return new Promise((resolve, reject) => {
      if (file === undefined) {
        resolve(null);
      }

      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Problem parsing input file."));
      };

      temporaryFileReader.onloadend = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(file);
    });
  }

  render() {
    return (
      <div id="page">
        <h1>Publish a new post</h1>

        <form onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="title">Title:</label>
              <input type="text" name="title" minLength="10" maxLength="80" required
              value={this.state.title} onChange={this.handleInputChange} disabled={this.state.sendingToServer}/>
            </div>

            <div className="form-group">
              <label htmlFor="username">Your name:</label>
              <input type="text" name="username" minLength="5" maxLength="32" required
              value={this.state.username} onChange={this.handleInputChange} disabled={this.state.sendingToServer}/>
            </div>
          </div>
          <br/>
          <div className="form-group">
            <label htmlFor="content">Message:</label>
            <textarea name="content" rows="5" minLength="50" maxLength="2000" required
            value={this.state.content} onChange={this.handleInputChange} disabled={this.state.sendingToServer}/>
          </div>
          <br/>
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="image">Illustration:</label>
              <input type="file" name="image" accept="image/*" ref={this.fileInput}
              disabled={this.state.sendingToServer}/>
            </div>
            <div className="form-group">
              <label htmlFor="tags">Tags (separated by commas)</label>
              <input type="text" name="tags" maxLength="50"
              value={this.state.tags} onChange={this.handleInputChange} disabled={this.state.sendingToServer}/>
            </div>
          </div>
          <br/>
          <input type="submit" value="Publish" disabled={this.state.sendingToServer} />
        </form>
      </div>
    )
  }
}

export default PublishPage;