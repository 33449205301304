import logo from './logo.svg';
import './CSS/App.css';
import IndexPage from './IndexPage';
import PublishPage from './PublishPage';
import ArticlePage from './ArticlePage';
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";


class App extends React.Component {
  
  
  render() {
    return (
      <Router >
      <div className="App">
          <header className="App-header horizontal-flex">
            <img src={logo} className="App-logo" alt="logo" />
            <h2><Link to="/" className="App-link">My Website</Link></h2>
            <span className="space" />
            <nav>
              <ul id="header-menu">
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/publish">New post</NavLink></li>
              </ul>
            </nav>
          </header>

          <div id="body">
            <Switch>
              <Route path="/publish">
                <PublishPage />
              </Route>
              <Route path="/post/:id">
                <ArticlePage />
              </Route>
              <Route path="/">
                <IndexPage />
              </Route>
            </Switch>
          </div>

        </div>
      </Router>
    );
  }

}

export default App;
