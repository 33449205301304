import React from 'react';
import '../CSS/VoteZone.css'
import { SkeletonLine } from './Skeleton';

class VoteZone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            upvoted: false,
            downvoted: false
        }

        this.upvote = this.upvote.bind(this);
        this.downvote = this.downvote.bind(this);

        this.skeleton = (props.upvotes === undefined);
    }

    upvote() {
        if (this.state.upvoted) {
            this.resetVote()
        } else {
            this.setState(state => {
                return {
                    upvoted: true, downvoted: false,
                }
            })
            this.props.voteArticle(1);
        }   
    }

    resetVote() {
        this.setState(state => {
            return {
                upvoted: false, downvoted: false,
            }
        })
        this.props.voteArticle(0);
    }

    downvote() {
        if (this.state.downvoted) {
            this.resetVote();
        } else {
            this.setState(state => {
                return {
                    upvoted: false, downvoted: true,
                }
            })
            this.props.voteArticle(-1);
        }
    }

    render() {
        return (
            <div className="vote-zone">
                <svg className={"vote-btn upvote-btn" + (this.state.upvoted ? " on" : "")} width="36" height="36" onClick={this.upvote}>
                    <path d="M2 10h32L18 26 2 10z" fill="currentColor"></path>
                </svg>
                <div className="vote-counter">
                    {this.skeleton ? <SkeletonLine width="2.5em"/> : this.props.upvotes - this.props.downvotes}
                </div>
                <svg className={"vote-btn downvote-btn" + (this.state.downvoted ? " on" : "")} width="36" height="36" onClick={this.downvote}>
                    <path d="M2 10h32L18 26 2 10z" fill="currentColor"></path>
                </svg>
            </div>
        )
    }
}

export default VoteZone;