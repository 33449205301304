import Article from './Components/Article';
import './CSS/IndexPage.css';
import React from "react";

import { API_URL, MAX_POSTS } from './settings.json'

class IndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      feed: [],
      sort_by: "date",
      loading: true
    }

    this.handleSortChange = this.handleSortChange.bind(this);
  }

  componentDidMount() {
    this.getArticlesFromAPI();
  }

  get_sort(sort_by) {
    switch (sort_by) {
      case "date":
        return (a, b) => new Date(b.postedAt || 0) - new Date(a.postedAt || 0);
      case "votes":
        return (a, b) => (b.upvotes - b.downvotes) - (a.upvotes - a.downvotes)
      case "user":
        return (a, b) => a.username.localeCompare(b.username)
      default:
        return (a, b) => 1;
    }
  }

  handleSortChange(event) {
    this.setState(state => {
      return {
        feed: state.feed.sort(this.get_sort(event.target.value)),
        sort_by: event.target.value
      }
    })
  }

  async getArticlesFromAPI() {
    const response = await fetch(API_URL + "/posts");
    let jsonData = await response.json();
    jsonData.sort(this.get_sort(this.state.sort_by));
    this.setState(state => ({ feed: jsonData, loading: false }));
  };

  voteArticle(article_id, vote) {
    const newFeed = this.state.feed.map((article, i) => {
      if (article.id === article_id) {
        if (article.voted === vote) return article;
        // undo precedent vote
        if (article.voted === 1) {
          article.upvotes -= 1;
        } else if (article.voted === -1) {
          article.downvotes -= 1;
        }
        // add new vote
        if (vote === 1) {
          article.upvotes += 1;
        } else if (vote === -1) {
          article.downvotes += 1
        }
        // refresh history
        article.voted = vote;
      }
      return article;
    }).sort(this.get_sort(this.state.sort_by))
    this.setState(state => ({ feed: newFeed }));
  }

  render() {
    return (
      <div>
        <div id="feed-header">
          <h1>My Feed</h1>
          <span className="flex-space"></span>
          <div id="sort-zone">
            <span>Sort by</span>

            <select value={this.state.sort_by} onChange={this.handleSortChange} >
              <option value="date">Post date</option>
              <option value="user">Author</option>
              <option value="votes">Votes</option>
            </select>
          </div>
        </div>

        <div id="feed">
          {this.state.loading ? (
            <Article article={undefined} voteArticle={() => { }} />
          ) : (
            <div>
              {this.state.feed.slice(0, MAX_POSTS).map((article, i) => (

                <div key={article.id}>
                  <div className="article">
                    <Article article={article} voteArticle={(vote) => this.voteArticle(article.id, vote)} />
                  </div>
                  {(i < this.state.feed.length - 1) ? <hr className="article-sep" /> : null}
                </div>

              ))}
            </div>
          )}
        </div>

      </div>
    );
  }
}


export default IndexPage;