import React from "react";
import { withRouter } from "react-router-dom";
import './CSS/ArticlePage.css';
import { SkeletonLine } from './Components/Skeleton';

import { API_URL } from './settings.json'

class ArticlePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            article: undefined
        }
    }

    formatDate(raw_date) {
        const options = {  weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        const date = new Date(raw_date);
        if (!date) return "?";
        let result = date.toLocaleDateString(undefined, options);
        const interval = (date - new Date())/1000;
        if (-interval < 86400*2) {
            result += " ("
            const formatter = new Intl.RelativeTimeFormat(undefined, {numeric: 'auto'})
            if (-interval > 86400) {
                result += formatter.format(Math.round(interval/86400), 'day')
            } else if (-interval > 3600) {
                result += formatter.format(Math.round(interval/3600), 'hour')
            } else {
                result += formatter.format(Math.round(interval/60), 'minute')
            }
            result += ")";
        }
        return result;
    }

    componentDidMount() {
        // fetch the article once the ID has been updated
        this.setState({id: this.props.match.params.id},
            this.getArticleFromAPI);
    }
    
    async getArticleFromAPI() {
        // safe guard
        if (!this.state.id) {
            return;
        }
        // fetch the article
        const response = await fetch(API_URL + `/post/${this.state.id}`);
        // if the article does not exist, redirect to home
        if (response.status === 404) {
            this.props.history.push('/');
            return;
        }
        this.setState({article: await response.json()})
    }

    render() {
        return (
            <div id="article-page">
                <div id="title">
                    <h1>{this.state.article?.title ?? <SkeletonLine/>}</h1>
                    <i id="author">Posted by {this.state.article?.username}</i>
                </div>

                <div id="info-box">
                    {this.state.article?.image ?
                        <div id="img">
                            <img className="image" src={this.state.article.image} alt="Post illustration" />
                            <hr class="hide-on-mobile" />
                        </div>
                        : null}
                    <div>
                        <span>Tags:</span>
                        <div id="tags">
                        {this.state.article?.tags?.map(tag => (
                            <span key={tag} className="tag">{tag}</span>
                        ))  ?? <SkeletonLine/>}
                        </div>
                        <hr />
                        {this.state.article?.postedAt ?
                        <div>
                            <span>Posted on {this.formatDate(this.state.article.postedAt)}</span>
                        </div>
                        : null}
                    </div>
                </div>

                <div id="content">
                    {this.state.article?.content ?? <SkeletonLine width="10em"/>}
                </div>

            </div>
        )
    }
}

export default withRouter(ArticlePage);