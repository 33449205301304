import React from 'react';
import '../CSS/Article.css';
import { SkeletonLine } from './Skeleton';
import VoteZone from './VoteZone';

class Article extends React.Component {
    constructor (props) {
        super(props);
        this.skeleton = (props.article === undefined);
    }

    formatDate(raw_date) {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(raw_date).toLocaleDateString(undefined, options);
    }

    render() {
        return (
            <div className="horizontal-flex">
                <VoteZone upvotes={this.props.article?.upvotes} downvotes={this.props.article?.downvotes} voteArticle={this.props.voteArticle} />
                <a className="article-box" href={`/post/${this.props.article?.id}`}>
                    <div className="name-content-img">
                        <div className="name-content">
                            <div className="article-header">
                                <span className="article-title">
                                    {this.skeleton ? <SkeletonLine/> : this.props.article.title}
                                </span>
                                <span className="article-author">
                                    {this.skeleton ? null : "by " + this.props.article.username}
                                </span>
                            </div>
                        
                            <div className={"article-content" + (this.props.article?.image ? " with-img" : "")}>
                                {this.skeleton ? <SkeletonLine width="10em"/> : this.props.article.content}
                            </div>
                        </div>
                        {this.props.article?.image ?
                        <img className="article-image" src={this.props.article.image} alt="Post illustration" />
                        : null}
                    </div>
                    <div className="article-footer horizontal-flex">
                        <div className="tags">
                        {this.props.article?.tags.map(tag => (
                            <span key={tag} className="tag">{tag}</span>
                        ))}
                        </div>
                        <span className="space"></span>
                        {this.props.article?.postedAt ?
                            <div className="article-date">{this.formatDate(this.props.article.postedAt)}</div>
                            : null}
                    </div>
                </a>

                
            </div>
        );
    }
}

export default Article;